import * as React from 'react'
import NavHeader from '../components/navHeader'
import SiteFooter from '../components/footer'
import PageTitle from '../components/pageTitle'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import * as aboutStyle from '../styles/about.module.scss'
import { graphql } from 'gatsby'


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const About = ({data}) => {
  return (
    <main>
        <NavHeader/>
        <PageTitle pageTitle="O spoločnosti"/>
            <div className="mainContainer">
                <div>
                        <section>
                        <div className="sectionContainer">
                            <h1>Úvod</h1>
                            <p>
                            GlobalTel, a.s. predstavuje už od roku 2004 významnú spoločnosť so zameraním na informačné systémy a
                            komunikačnú infraštruktúru pre zavádzanie a rozvoj eGovernment služieb v Slovenskej republike.
                            </p>
                            <p>
                            Nosnými projektami sú dodávky modulov a služieb pre chrbticovú infraštruktúru integrovaného informačného
                            systému štátu - <strong>Ústredný portál verejnej správy (ÚPVS).</strong>
                            </p>
                        </div>
                       
                        <div className="section-ln-sp-item"></div>
                        </section>
                        <section>
                        <h1>Náš cieľ</h1>
                        <div className={aboutStyle.goalContainer}>
                            <div>
                            <p>
                                Implementáciou riešení v oblasti eGovernment pomáhame rýchlo a efektívne inovovať a konsolidovať IT
                                architektúru a procesy verejnej správy pri zameraní sa na využívanie služieb štátu občanmi i
                                podnikateľmi.
                            </p>
                            <p>
                                Prinášame inovatívne riešenia implementované na integrovanej informačno - komunikačnej infraštruktúre.
                                Spájame
                                moderné architektúry s technicko-procesným know-how a s osvedčenými technológiami.
                            </p>
                            </div>
                            <div className={aboutStyle.goalImage}>
                            <StaticImage alt="Company illustration" src="../images/skyline.svg"/>
                            </div>
                        </div>
                        
                        <div className="section-ln-sp-item"></div>
                        </section>
                        <section id="certificates">
                        <h1>Certifikáty</h1>
                        <p>Spoločnosť má do praxe zavedené medzinárodné certifikáty Systému manažérstva
                            kvality, IT a bezpečnosti aplikácií.</p>
                        <div>
                        <SimpleReactLightbox>
                        <SRLWrapper>
                        <div className={aboutStyle.certificatesContainer}>
                            {data.gallery.edges.map(({node}) =>(
                                <div key={node.id} className={aboutStyle.certificate}>
                                <a href={node.publicURL}>
                                <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('_').join(' ').split('.')[0]} />
                                </a>
                                <p>{node.base.split('_').join(' ').split('.')[0]}</p>
                                </div>
                            ))}
                        </div>
                        </SRLWrapper>
                        </SimpleReactLightbox>
                        </div>
                        </section>
                    </div>
                </div>
        <SiteFooter/>
    </main>
  )
}

export const query = graphql`
   query {
    gallery: allFile(filter: {relativeDirectory: {eq: "cert"}}) {
        edges {
          node {
            id
            base
            name
            publicURL
            childImageSharp {
              gatsbyImageData(
                height: 420
                width: 277
                transformOptions: {fit: COVER}
                placeholder: BLURRED
                webpOptions: {quality: 10}
              )
            }
          }
        }
      }
  }
`

export default About